import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "server-side-rendering-ssr"
    }}>{`Server-Side Rendering (SSR)`}</h1>
    <h2 {...{
      "id": "setup"
    }}>{`Setup`}</h2>
    <p>{`To test how your hook will behave when rendered on the server, you can change your import to the use
the `}<inlineCode parentName="p">{`server`}</inlineCode>{` module:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { renderHook } from '@testing-library/react-hooks/server'
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`SSR is only available when using the `}<inlineCode parentName="p">{`react-dom`}</inlineCode>{` renderer. Please refer to the
`}<a parentName="p" {...{
          "href": "/installation#peer-dependencies"
        }}>{`installation guide`}</a>{` for instructions and supported versions.`}</p>
    </blockquote>
    <p>{`This import has the same `}<a parentName="p" {...{
        "href": "/reference/api"
      }}>{`API as the standard import`}</a>{` except the behaviour changes
to use SSR semantics.`}</p>
    <h2 {...{
      "id": "hydration"
    }}>{`Hydration`}</h2>
    <p>{`The result of rendering your hook is static and not interactive until it is hydrated into the DOM.
This can be done using the `}<inlineCode parentName="p">{`hydrate`}</inlineCode>{` function that is returned from `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{`.`}</p>
    <p>{`Consider the `}<inlineCode parentName="p">{`useCounter`}</inlineCode>{` example from the `}<a parentName="p" {...{
        "href": "/usage/basic-hooks"
      }}>{`Basic Hooks section`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useState, useCallback } from 'react'

export default function useCounter() {
  const [count, setCount] = useState(0)
  const increment = useCallback(() => setCount((x) => x + 1), [])
  return { count, increment }
}
`}</code></pre>
    <p>{`If we try to call `}<inlineCode parentName="p">{`increment`}</inlineCode>{` immediately after server rendering, nothing happens and the hook is
not interactive:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks/server'
import useCounter from './useCounter'

test('should increment counter', () => {
  const { result } = renderHook(() => useCounter())

  act(() => {
    result.current.increment()
  })

  expect(result.current.count).toBe(1) // fails as result.current.count is still 0
})
`}</code></pre>
    <p>{`We can make the hook interactive by calling the `}<inlineCode parentName="p">{`hydrate`}</inlineCode>{` function that is returned from
`}<inlineCode parentName="p">{`renderHook`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks/server'
import useCounter from './useCounter'

test('should increment counter', () => {
  const { result, hydrate } = renderHook(() => useCounter())

  hydrate()

  act(() => {
    result.current.increment()
  })

  expect(result.current.count).toBe(1) // now it passes
})
`}</code></pre>
    <p>{`Anything that causes the hook's state to change will not work until `}<inlineCode parentName="p">{`hydrate`}</inlineCode>{` is called. This
includes both the `}<a parentName="p" {...{
        "href": "/reference/api#rerender"
      }}><inlineCode parentName="a">{`rerender`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "/reference/api#unmount"
      }}><inlineCode parentName="a">{`unmount`}</inlineCode></a>{`
functionality.`}</p>
    <h3 {...{
      "id": "effects"
    }}>{`Effects`}</h3>
    <p>{`Another caveat of SSR is that `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` and `}<inlineCode parentName="p">{`useLayoutEffect`}</inlineCode>{` hooks, by design, do not run on when
rendering.`}</p>
    <p>{`Consider this `}<inlineCode parentName="p">{`useTimer`}</inlineCode>{` hook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useState, useCallback, useEffect } from 'react'

export default function useTimer() {
  const [count, setCount] = useState(0)
  const reset = useCallback(() => setCount(0), [])
  useEffect(() => {
    const intervalId = setInterval(() => setCount((c) => c + 1, 1000))
    return () => {
      clearInterval(intervalId)
    }
  })
  return { count, reset }
}
`}</code></pre>
    <p>{`Upon initial render, the interval will not start:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks/server'
import useTimer from './useTimer'

test('should start the timer', async () => {
  const { result, waitForValueToChange } = renderHook(() => useTimer(0))

  await waitForValueToChange(() => result.current.count) // times out as the value never changes

  expect(result.current.count).toBe(1) // fails as result.current.count is still 0
})
`}</code></pre>
    <p>{`Similarly to updating the hooks state, the effect will start after `}<inlineCode parentName="p">{`hydrate`}</inlineCode>{` is called:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks/server'
import useTimer from './useTimer'

test('should start the timer', async () => {
  const { result, hydrate, waitForValueToChange } = renderHook(() => useTimer(0))

  hydrate()

  await waitForValueToChange(() => result.current.count) // now resolves when the interval fires

  expect(result.current.count).toBe(1)
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      